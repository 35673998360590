import gql from "graphql-tag";

export default gql`
  fragment userPublic on UserInterface {
    id
    firstName
    lastName
    email
    role
    gender
    phoneNumber
    profilePic
  }
`;
