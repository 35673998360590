import gql from "graphql-tag";

import user from "apis/fragments/user";

export default gql`
  fragment clientUserInterface on ClientUserInterface {
    ...user
  }
  ${user}
`;
