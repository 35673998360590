type Person = {
  firstName: string;
  lastName: string;
};

// eslint-disable-next-line import/prefer-default-export
export const fullName = (p?: Person) =>
  (p && `${p.firstName} ${p.lastName}`) || "";

export const isCrewMember = (role: string) =>
  role === "BusinessDeveloper" || role === "AccountManager" || role === "Admin";
