import { CrewMember, Sailor, Client } from "types";
import { fullName } from "utils/users";

const defaultMetaTitle = {
  activities: "Activités",
  dashboard: "Dashboard",
  contracts: "Contrats",
  error: "404",
  expenses: "Notes de frais",
  help: "Centre d'aide",
  invoices: "Factures",
  missions: "Missions",
  payslips: "Bulletins de paie",
  profile: "Mon Compte",
  quotes: "Mes devis",
  crew: {
    userEdit: (user: CrewMember | Sailor) => fullName(user),
    clients: "Clients",
    crewMembers: "Équipage",
    dashboard: "Dashboard",
    editPaymments: (paymentState: string) =>
      paymentState === "paid" ? "Informations versement" : "Nouveau versement",
    editClient: (client: Client) => client.name,
    exportLeeto: "Exports Leeto",
    exportInvoicing: "Exports facturation",
    exportMissions: "Exports missions",
    exportPayments: "Exports paiements",
    exportSailors: "Exports matelots",
    exportClients: "Exports clients",
    invoices: "Factures",
    newCrewMember: "Nouveau membre d'équipage",
    newSailor: "Nouveau matelot",
    newClient: "Nouveau client",
    newInvoice: "Nouvelle facture",
    newPayment: (type: string) =>
      `Nouveau ${type === "refund" ? "remboursement" : "versement"}`,
    onboardings: "Embarquement",
    payables: "Activités",
    payments: "Paiements",
    profile: "Mon Compte",
    sailors: "Matelots",
    simulator: "Simulateur",
    statistics: "Statistiques",
  },
  clientAdmin: {
    clientUsers: "Accès simulateur",
    exportInvoicing: "Exports facturation",
    exportMissions: "Exports missions",
  },
};

export default defaultMetaTitle;
