import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Fade,
  CircularProgressProps,
} from "@mui/material";

import AnchorLogo from "public/images/anchor-logo.svg";

interface LoaderSpinnerProps extends CircularProgressProps {
  size?: number;
  message?: string;
  delay?: number;
  logo?: boolean;
}

const LoaderSpinner = ({
  size = 60,
  thickness = 2,
  message,
  delay = 0,
  logo,
  ...props
}: LoaderSpinnerProps) => {
  const logoSize = size * 0.75;
  const [display, setDisplay] = useState(false);

  useEffect(() => setDisplay(true), []); // the Fade starts every time the Loader is rendered

  return (
    <Fade
      in={display}
      style={{
        transitionDelay: `${delay}ms`,
      }}
      unmountOnExit
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          "& *": { color: "secondary.main" },
          "& .AnchorLogo": { position: "absolute" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={size} thickness={thickness} {...props} />
          {logo && (
            <AnchorLogo
              width={logoSize}
              height={logoSize}
              className="AnchorLogo"
            />
          )}
        </Box>
        {message && (
          <Box
            sx={{
              mt: 3,
              textAlign: "center",
            }}
          >
            <Typography>{message}</Typography>
          </Box>
        )}
      </Box>
    </Fade>
  );
};

export default LoaderSpinner;
