import { Fragment, useState } from "react";
import { useRouter } from "next/router";
import {
  DashboardOutlined,
  GroupsOutlined,
  Euro,
  PaymentsOutlined,
  Domain,
  DirectionsBoat,
  WorkOutline,
  CalculateOutlined,
  ExpandMoreOutlined,
  ExpandLessOutlined,
  AssignmentOutlined,
  RequestQuoteOutlined,
  InsertChartOutlined,
  ContactSupportOutlined,
  ExploreOutlined,
} from "@mui/icons-material";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Collapse,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import {
  CalendarClock,
  FileDocumentOutline,
  AccountTie,
  FolderMultipleOutline,
  ChartArc,
  CurrencyEur,
  RocketLaunchOutline,
} from "mdi-material-ui";

const ADMIN_TABS = [
  {
    title: "Equipage",
    Icon: DirectionsBoat,
    route: "/crew/crew-members",
  },
];

const CREW_MEMBER_TABS = [
  { title: "Dashboard", Icon: DashboardOutlined, route: "/crew" },
  {
    title: "Matelots",
    Icon: GroupsOutlined,
    route: "/crew/sailors?state=invited%252Conboarding%252Conboarded",
  },
  { title: "Facturation", Icon: Euro, route: "/crew/invoices" },
  { title: "Versements", Icon: PaymentsOutlined, route: "/crew/payments" },
  { title: "Clients", Icon: Domain, route: "/crew/clients" },
  {
    title: "Onboardings",
    Icon: RocketLaunchOutline,
    route: "/crew/onboardings",
  },
  { title: "Simulateur", Icon: CalculateOutlined, route: "/crew/simulator" },
  { title: "Statistiques", Icon: ChartArc, route: "/crew/stats" },
  {
    title: "Exports",
    Icon: InsertChartOutlined,
    // When route is an Array, you can specify 'child tabs' to a top level tab
    route: [
      { title: "Facturation", route: "/crew/exports/invoicing" },
      { title: "Leeto", route: "/crew/exports/employee-committee-services" },
      { title: "Missions", route: "/crew/exports/missions" },
      { title: "Matelots", route: "/crew/exports/sailors" },
      { title: "Versements", route: "/crew/exports/payments" },
      { title: "Clients", route: "/crew/exports/clients" },
    ],
  },
];

const CLIENT_ADMIN_TABS = [
  { title: "Tableau de bord", Icon: DashboardOutlined, route: "/client" },
  { title: "Factures", Icon: CurrencyEur, route: "/client/invoices" },
  { title: "Simulateur", Icon: ChartArc, route: "/client/simulator" },
  {
    title: "Accès simulateur",
    Icon: GroupsOutlined,
    route: "/client/client-users",
  },
  {
    title: "Exports",
    Icon: InsertChartOutlined,
    // When route is an Array, you can specify 'child tabs' to a top level tab
    route: [
      { title: "Facturation", route: "/client/exports/invoicing" },
      { title: "Missions", route: "/client/exports/missions" },
    ],
  },
];

const CLIENT_USER_TABS = [
  { title: "Simulateur", Icon: ChartArc, route: "/client/simulator" },
];

const SAILOR_TABS = [
  { title: "Tableau de bord", Icon: DashboardOutlined, route: "/" },
  { title: "Activités", Icon: CalendarClock, route: "/activities" },
  { title: "Notes de frais", Icon: FileDocumentOutline, route: "/expenses" },
  { title: "Bulletins de paie", Icon: Euro, route: "/payslips" },
  { title: "Factures client", Icon: AccountTie, route: "/invoices" },
  {
    title: "Contrats",
    Icon: FolderMultipleOutline,
    route: "/contracts",
  },
  { title: "Documents", Icon: AssignmentOutlined, route: "/profile?tab=2" },
  { title: "Missions", Icon: WorkOutline, route: "/missions" },
  { title: "Devis", Icon: RequestQuoteOutlined, route: "/quotes" },
  { title: "Centre d'aide", Icon: ContactSupportOutlined, route: "/help" },
  {
    title: "Communauté",
    Icon: ExploreOutlined,
    route:
      "https://la-boussole-embarq.circle.so/join?invitation_token=89d50f4c72925c7333ba9158a8113f2b424ba043-eb92a9ad-6ac9-4489-8fc1-3493e9a9bb50",
  },
];

interface SidebarTabListProps {
  role: string;
  onClickItem?: () => void;
}

interface TabItemProps {
  title: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  route?: string;
  isChild?: boolean;
  hasChildSelected?: boolean;
}

const SidebarTabList = ({ role, onClickItem }: SidebarTabListProps) => {
  const router = useRouter();
  const [openedTabs, setOpenedTabs] = useState<Record<string, boolean>>({});

  const onClick = (route: string, newTab: boolean) => {
    if (newTab) window.open(route, "_blank");
    else router.push(route);
    if (onClickItem) onClickItem();
  };

  let navTabs = [];
  if (role === "Sailor") navTabs = SAILOR_TABS;
  else if (role === "ClientAdmin") navTabs = CLIENT_ADMIN_TABS;
  else if (role === "ClientUser") navTabs = CLIENT_USER_TABS;
  else navTabs = CREW_MEMBER_TABS;

  if (role === "Admin") navTabs = navTabs.concat(ADMIN_TABS);

  const TabItem = ({
    title,
    Icon,
    route,
    isChild,
    hasChildSelected,
  }: TabItemProps) => {
    const isTabSelected = router.pathname === route?.replace(/\?.+/, "");

    return (
      <ListItemButton
        selected={hasChildSelected || isTabSelected}
        onClick={(e) => {
          const newTab = e.metaKey || e.ctrlKey;
          if (route) onClick(route, newTab);
          else setOpenedTabs({ [title]: !openedTabs[title] });
        }}
        sx={{
          py: 2,
          "&.Mui-selected, &.Mui-selected:hover": {
            bgcolor: isChild
              ? "rgba(255, 255, 255, 0.08)"
              : "rgba(255, 255, 255, 0.15)",
          },
        }}
      >
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          primaryTypographyProps={{ variant: "buttonSmall" }}
          sx={{ paddingLeft: isChild ? 8 : 0, margin: 0 }}
        />
        {!route &&
          (openedTabs[title] ? <ExpandLessOutlined /> : <ExpandMoreOutlined />)}
      </ListItemButton>
    );
  };

  return (
    <List component="nav">
      {navTabs.map(({ title, Icon, route }) => {
        const childSelected =
          Array.isArray(route) &&
          route.find(({ route: r }) => router.pathname === r);
        return (
          <Fragment key={title}>
            <TabItem
              title={title}
              Icon={Icon}
              route={Array.isArray(route) ? undefined : route}
              hasChildSelected={Boolean(childSelected)}
            />
            {Array.isArray(route) && (
              <Collapse in={openedTabs[title]}>
                {route.map(({ title: childTitle, route: childRoute }) => (
                  <TabItem
                    key={childTitle}
                    title={childTitle}
                    route={childRoute}
                    isChild
                  />
                ))}
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};

export default SidebarTabList;
