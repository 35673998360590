import { ReactNode } from "react";
import { useRouter } from "next/router";
import { Box, Fab } from "@mui/material";
import { HelpCircleOutline } from "mdi-material-ui";

import Infobar from "./Infobar";
import { useInfobar } from "./InfobarContext";
import { ME_QUERY, MeData } from "apis/queries/me";
import LoadingOrError from "components/LoadingOrError";
import Sidebar from "components/Sidebar";
import useQueryStale from "utils/useQueryStale";

interface LayoutProps {
  children?: ReactNode;
}

const NavigationLayout = ({ children }: LayoutProps) => {
  const { isInfobarVisible } = useInfobar();

  const router = useRouter();
  const { loading, error, data } = useQueryStale<MeData>(ME_QUERY);

  if (error || !data) return <LoadingOrError loading={loading} />;

  const { role } = data.me;

  const isHelpPage = router.pathname.startsWith("/help");

  // const handleCloseInfobar = () => {
  //   setInfobarVisible(false);
  // };

  return (
    <>
      {isInfobarVisible && (
        <Infobar label="Maintenance de l'application en cours. Il est possible que certaines fonctionnalités ne fonctionnent pas." />
      )}
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          position: "relative",
        }}
      >
        <Sidebar userData={data.me} />
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={({ mixins }) => ({
              ...mixins.toolbar,
              minHeight: 50,
              height: 50,
              display: { md: "none" },
            })}
          />
          {children}
        </Box>
        {role === "Sailor" && !isHelpPage && (
          <Fab
            color="secondary"
            aria-label="help"
            onClick={() => router.push("/help")}
            sx={{
              position: "absolute",
              bottom: { xs: 10, md: 20 },
              right: { xs: 10, md: 20 },
            }}
          >
            <HelpCircleOutline />
          </Fab>
        )}
      </Box>
    </>
  );
};

export default NavigationLayout;
