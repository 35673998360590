import { ArrowBackOutlined } from "@mui/icons-material";
import { Typography, Button, ButtonProps } from "@mui/material";

interface BackButtonProps extends Pick<ButtonProps, "children" | "sx"> {
  onClick: () => void;
}

const BackButton = ({ children, onClick, sx }: BackButtonProps) => (
  <Button
    startIcon={<ArrowBackOutlined />}
    sx={sx}
    color="primary"
    onClick={onClick}
    size="small"
  >
    <Typography sx={{ display: { xs: "none", md: "block" } }}>
      {children}
    </Typography>
  </Button>
);

export default BackButton;
