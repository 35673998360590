import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";

import EmbarqLogo from "public/images/embarq-logo.svg";

interface MobileAppBarProps {
  appPro?: boolean;
  onMenuOpen: () => void;
}

const MobileAppBar = ({ onMenuOpen, appPro = false }: MobileAppBarProps) => (
  <AppBar
    position="fixed"
    sx={({ palette, zIndex }) => ({
      background: appPro ? palette.specials.deepBlue : palette.specials.sidebar,
      zIndex: zIndex.drawer,
    })}
  >
    <Toolbar
      sx={({ mixins }) => ({ ...mixins.toolbar, minHeight: 50, height: 50 })}
    >
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={onMenuOpen}
        size="large"
        sx={{ position: "absolute" }}
      >
        <MenuOutlinedIcon />
      </IconButton>
      <div style={{ margin: "auto" }}>
        <EmbarqLogo width={34} viewBox={null} />
      </div>
    </Toolbar>
  </AppBar>
);

export default MobileAppBar;
