import Head from "next/head";

type TitleProps = {
  label: string;
};

const Title = ({ label }: TitleProps) => (
  <Head>
    <title>{label} | Embarq</title>
  </Head>
);

export default Title;
