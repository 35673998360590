import { useRef } from "react";
import { useQuery, DocumentNode, QueryHookOptions } from "@apollo/client";

const useQueryStale = <TData>(
  query: DocumentNode,
  options?: QueryHookOptions<TData>
) => {
  const cachedData = useRef<TData | undefined>(undefined);

  const queryResult = useQuery<TData>(query, options);
  const { loading, data } = queryResult;

  if (!loading && data) cachedData.current = data;

  return { ...queryResult, data: cachedData.current };
};

export default useQueryStale;
