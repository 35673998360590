import { Box, Typography } from "@mui/material";

import LoaderSpinner from "components/LoaderSpinner";

interface LoadingOrErrorProps {
  loading: boolean;
  minHeight?: number;
  logo?: boolean;
  errorMessage?: string;
}

const LoadingOrError = ({
  logo,
  minHeight,
  loading,
  errorMessage,
}: LoadingOrErrorProps) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight,
      height: minHeight ? undefined : "100%",
    }}
  >
    {loading ? (
      <LoaderSpinner logo={logo} delay={200} />
    ) : (
      <Typography>{errorMessage || "Error"}</Typography>
    )}
  </Box>
);

export default LoadingOrError;
