import gql from "graphql-tag";

import userPublic from "./userPublic";

export default gql`
  fragment user on UserInterface {
    ...userPublic
    unconfirmedEmail
    createdAt
    disabledAt
  }
  ${userPublic}
`;
