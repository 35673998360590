import gql from "graphql-tag";

import client from "apis/fragments/client";
import clientUserInterface from "apis/fragments/clientUserInterface";
import { ClientUserable } from "types";

export type GetClientUserableData = {
  node: ClientUserable;
};

export default gql`
  query getClientUserable($id: ID!) {
    node(id: $id) {
      ...clientUserInterface
      ... on ClientUser {
        client {
          ...client
        }
      }
      ... on ClientAdmin {
        client {
          ...client
        }
      }
    }
  }
  ${clientUserInterface}
  ${client}
`;
