import { useState } from "react";
import { Box } from "@mui/material";

import { MeData } from "apis/queries/me";
import MobileAppBar from "components/MobileAppBar";
import NavigationDrawer from "components/NavigationDrawer";

interface SidebarProps {
  userData: MeData["me"];
}

const Sidebar = ({ userData }: SidebarProps) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const { role } = userData;

  const isClientUserable = role === "ClientUser" || role === "ClientAdmin";

  return (
    <nav>
      <Box sx={{ display: { md: "none" } }}>
        <MobileAppBar
          onMenuOpen={handleDrawerToggle}
          appPro={isClientUserable}
        />
        <NavigationDrawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          userData={userData}
          onOpen={setMobileOpen}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        />
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <NavigationDrawer
          variant="permanent"
          open
          userData={userData}
          onOpen={setMobileOpen}
        />
      </Box>
    </nav>
  );
};

export default Sidebar;
