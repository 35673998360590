import gql from "graphql-tag";

export default gql`
  fragment client on Client {
    id
    name
    invoicingName
    shareCapital
    registrationNumber
    legalStatus
    address
    invoicingAddress
    registrationOffice
    vatPercent
    vatNumber
    currency
    logo
    assignedBank
    joinReports
    managementFeesPercent
    managementFeesCapped
    managementFeesCap
    extraManagementFeesPercent
    paymentOption
    activityReportProcessDetails
    activityReportFormat
    mandatoryInvoicesNote
    brandDocuments
    tLocale
    renewalResponsibility
    renewalReminderType
    paymentPeriod
    endOfMonthPayment
    autoInvoice
  }
`;
