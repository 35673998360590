import { Box, Drawer, DrawerProps } from "@mui/material";

import { MeData } from "apis/queries/me";
import LoggedInUserInfo from "components/LoggedInUserInfo";
import SidebarTabList from "components/SidebarTabList";
import EmbarqLogoPro from "public/images/embarq-logo-pro.svg";
import EmbarqLogo from "public/images/embarq-logo.svg";
import palette from "utils/theme/palette";

interface NavigationDrawerProps extends DrawerProps {
  userData: MeData["me"];
  onOpen: (v: boolean) => void;
}

const drawerWidth = 240;

const NavigationDrawer = ({
  onOpen,
  userData,
  ...props
}: NavigationDrawerProps) => {
  const { role, admin } = userData;
  const userRole = admin ? "Admin" : role;

  const isClientUserable = role === "ClientUser" || role === "ClientAdmin";

  return (
    <Drawer
      sx={{
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          userSelect: "none",
          border: "none",
          position: "relative",
          height: "100vh",
          color: "white",
          overflow: "hidden",
          background: isClientUserable
            ? palette.specials.deepBlue
            : palette.specials.sidebar,
        },
      }}
      {...props}
    >
      <Box
        sx={{
          zIndex: 100,
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <LoggedInUserInfo user={userData} />
        <SidebarTabList role={userRole} onClickItem={() => onOpen(false)} />
      </Box>
      <Box
        sx={{
          display: "flex",
          mb: 3,
          width: drawerWidth,
          justifyContent: "center",
        }}
      >
        {isClientUserable ? (
          <EmbarqLogoPro fill="currentColor" />
        ) : (
          <EmbarqLogo fill="currentColor" />
        )}
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
