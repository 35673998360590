import type { ComponentType } from "react";

import NavigationLayout from "components/NavigationLayout";

const withNavigationLayout =
  <P extends {}>(Component: ComponentType<P>) =>
  (props: P) => (
    <NavigationLayout>
      <Component {...props} />
    </NavigationLayout>
  );

export default withNavigationLayout;
