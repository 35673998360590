import { MouseEvent, useState } from "react";
import { useRouter } from "next/router";
import { useApolloClient, useQuery } from "@apollo/client";
import { ExpandMoreOutlined } from "@mui/icons-material";
import {
  Box,
  Typography,
  Avatar,
  Button,
  List,
  ListItemButton,
  Popover,
} from "@mui/material";

import GET_CLIENT_USERABLE_DATA, {
  GetClientUserableData,
} from "apis/queries/clientUserable";
import { MeData } from "apis/queries/me";
import logout from "utils/logout";
import { fullName } from "utils/users";

const LoggedInUserInfo = ({ user }: { user: MeData["me"] }) => {
  const apolloClient = useApolloClient();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { role, profilePic } = user;
  const isClientUserable = role === "ClientUser" || role === "ClientAdmin";
  const { data } = useQuery<GetClientUserableData>(GET_CLIENT_USERABLE_DATA, {
    variables: { id: user.id },
    skip: !isClientUserable,
  });

  const clientName = data?.node?.client?.name;

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    apolloClient.clearStore().then(() => logout());
  };

  const handleProfileClick = () => {
    let URLPrefix = "/crew";

    if (role === "Sailor") URLPrefix = "";
    else if (isClientUserable) URLPrefix = "/client";

    router.push(`${URLPrefix}/profile`);
  };

  const MENU_TABS = [
    {
      index: 0,
      label: "Mon compte",
      onClick: handleProfileClick,
    },
    {
      index: 1,
      label: "Se déconnecter",
      onClick: handleLogout,
    },
  ];
  const open = Boolean(anchorEl);
  const id = open ? "menu-popover" : undefined;

  return (
    <Box
      sx={{
        m: ({ spacing }) => ({ xs: 1, md: spacing(1, 1, 5) }),
      }}
    >
      <Button
        color="inherit"
        onClick={handleOpenMenu}
        endIcon={<ExpandMoreOutlined />}
        startIcon={
          <Avatar
            alt={`${fullName(user)} avatar`}
            src={profilePic}
            sx={{
              borderRadius: "50%",
              border: "2px solid",
              borderColor: "common.white",
              color: "primary.contrastText",
              bgcolor: "primary.main",
              fontSize: "2rem",
            }}
          />
        }
      >
        <div>
          <Typography variant="body" align="left">
            {fullName(user)}
          </Typography>
          {clientName && (
            <Typography
              variant="caption"
              align="left"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 132,
                display: "block",
                opacity: 0.75,
              }}
            >
              {clientName}
            </Typography>
          )}
        </div>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ "& .MuiPaper-root": { minWidth: 210 } }}
      >
        <List disablePadding>
          {MENU_TABS.map(({ label, index, onClick }) => (
            <ListItemButton
              key={index}
              onClick={onClick}
              sx={{ typography: "bodySmall" }}
            >
              {label}
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Box>
  );
};

export default LoggedInUserInfo;
