import { Box } from "@mui/material";

import CATEGORY_ICON_TYPES, {
  CategoryIconTypes,
} from "constants/categoryIconTypes";
import theme from "utils/theme";

interface CategoryIconProps {
  type: CategoryIconTypes;
  disableGradient?: boolean;
  squared?: boolean;
  blue?: boolean;
}

const BadgeIcon = ({
  type,
  disableGradient = false,
  squared = false,
  blue,
}: CategoryIconProps) => {
  const category = CATEGORY_ICON_TYPES[type];
  const {
    icon: Icon,
    color: categoryColor,
    bgColor: categoryBgColor,
  } = category;

  const color = blue ? theme.palette.secondary.dark : categoryColor;
  const bgColor = blue ? theme.palette.secondary.background : categoryBgColor;

  return (
    <Box
      sx={{
        lineHeight: 0,
        borderRadius: squared ? "16px" : "50%",
        backgroundColor: bgColor,
        mr: { xs: 1.5, sm: 2 },
        p: 1.5,
        "& svg": {
          fill: disableGradient ? color : `url(#${color}-gradient) ${color}`,
        },
      }}
    >
      <Icon fontSize="small" htmlColor={color} />
    </Box>
  );
};

export default BadgeIcon;
